import routeIcon from "../assets/images/gis_route.svg";
import { SHIPMENT_SCOPE } from "../util/quotationUtil";
import { useFieldArray } from "react-hook-form";
import { Cargo, Container } from "../enquiry/enquiry-data";
import { AgMuiUn } from "../mui-components/AgAutoComplete";
import LabeledTextField from "../mui-components/LabeledTextField";
import { Add} from "@mui/icons-material";
import deleteicon from "../assets/images/deletei.svg";
import { ControlledDetailSwitch } from "./AgControlledDetailSwitch";
import { LabeledRadioButton } from "./AgLabeledRadioButton";
import RoleUtils from "../util/RoleUtils";
import { AgmodalStore, quoteDataRec } from "../state/CommonState";
import { useSnapshot } from "valtio";
import { useEffect, useState } from "react";
import { PERMISSIONS } from "../util/constants";
import { useGlobalState } from "../GlobalState";
export function Route({form,viewonly=false,validateAddress=true,callback = (f) => f,enableCrn=false}){
    useSnapshot(AgmodalStore)
    useSnapshot(quoteDataRec)
    const shipmentScopes = Object.values(SHIPMENT_SCOPE);
    const { control, watch, register, setValue, handleSubmit, reset,resetField, clearErrors, formState, trigger, getFieldState,getValues } = form;
    const { fields: multidest, append: appendMulitdest, remove: removeMultidest } = useFieldArray({
        control: control,
        name: "multidest"
    })
    const shipmentType = watch("mode");
    const Scope = watch("scope")
    const isFba = watch("isFba"); 
    const fbaalways = watch("fbaalways"); 
    const stuffing = watch("stuffingType");
    const deStuffing = watch("destuffingType");
    const outGuage = watch("showCargoDetailsOutGuage")
    const destcuntry = getValues("multidest.0.destination")?getValues("multidest.0.destination"):watch("multidest.0.destination")
    const { currentMenu } = useGlobalState();
    let showCargoDetail = false;
    let destination = Scope.split("-")[2];
    if(destination?.toLowerCase()==="door"){
        if((shipmentType.toLowerCase() === "fcl" && deStuffing.toLowerCase() === "dock" ) || shipmentType.toLowerCase() != "fcl"){
            showCargoDetail = true;
        }
    }else{
        if(showCargoDetail) showCargoDetail = false;
    }
    const validateGreaterThanZero = (value) => {
        return (value!=undefined && value.length!=0) || 'Required';
    };
    const [crn,setCrn] = useState(false)
    useEffect(()=>{
        if(!RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.CB_CUSTOMER_REF_NUMBER)){
            if(!crn){
                setCrn(true)
            }
        }
        if ((currentMenu == "Quotationv3" || currentMenu == "Enquiriesv3" || currentMenu == "Spot Booking WIP") && crn) {
            setCrn(false)
        }
        if(!crn && AgmodalStore.modalPage=='ViewEnquiry-v3'){
            setCrn(true)
        }
        if(enableCrn){
            setCrn(false)
        }
    },[AgmodalStore])
    
   
    return  <div className="flex flex-col min-h-[304px] w-[431px]">
    <div className="flex flex-col min-h-[304px] max-h-max w-full rounded-md bg-white shadow-custom p-[18px] gap-[16px]">
        <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row gap-[10px]">
           <img src={routeIcon}/>
           <p className="text-black font-bold">Route</p>
        </div>
        {((fbaalways!=undefined && fbaalways=='Yes') || (shipmentType==="LCL" && (Scope==="Port-to-Door" || Scope==="Door-to-Door"))) && <ControlledDetailSwitch control={control} isChecked={isFba} formKey="isFba"  title="FBA" disable={viewonly} onChange={()=>{
            setValue("multidest", [
                {
                    destination:"",
                    cargoDetails:[new Cargo()],
                }
            ]);
            setValue("containerDetails",[new Container()])
        }}/>}
        </div>
       { viewonly || quoteDataRec.quotationNum !== "" ? <div className="flex flex-row gap-2">
          <p className="text-blue-light font-bold font-roboto">{shipmentType}</p>
          <p className="text-blue-dark font-bold font-roboto">-</p>
          <p className="text-blue-dark font-bold font-roboto"> {Scope}</p>
       </div>  : <div className="grid grid-cols-2  h-[67px] place-content-stretch">
            {shipmentScopes.map((scope)=><LabeledRadioButton label={scope} selected={Scope===scope} onClick={()=>{
                if(!viewonly){
                    setValue("multidest", [
                        {
                            destination:"",
                            cargoDetails:[new Cargo()],
                        }
                    ]);
                    setValue("containerDetails",[new Container()])
                    setValue("origin", "");
                    setValue("scope", "");
                    // setValue("stuffingType","Factory")
                    // setValue("destuffingType","Factory")
                    if(fbaalways=='No' && isFba){
                        setValue("isFba",false)
                    }
                    setTimeout(()=>{
                        setValue("scope", scope);
                    },1)
                    clearErrors();
                }
                
            }} />)}
        </div>}
        { multidest.length>1 && <p className="text-blue-light font-bold">Origin Details</p>}
        <div className="flex flex-col w-full gap-[4px]">    
        <p className="text-xs font-bold text-[#2C358A]">{!viewonly && "Select"} Origin *</p>
        <AgMuiUn getValues={getValues} clearErrors={clearErrors} trigger={trigger} validateAddress={validateAddress} getFieldState={getFieldState} placeholder="Choose Origin" key={"origin"} rules={{ required: "Origin is Required" }} isDoor={Scope === SHIPMENT_SCOPE.D2D || Scope === SHIPMENT_SCOPE.D2P} control={control} name="origin" disabled={viewonly} />
        </div>
        { multidest.length>1 && <p className="text-blue-light font-bold">Destination Details</p>}
        {multidest.map((m,i)=>{
            return <div className="flex flex-col w-full gap-[4px]">
                {
                    (multidest.length>1)?<p className="text-xs font-bold text-[#2C358A]">{!viewonly && "Select"} Destination {i+1} *</p>:<p className="text-xs font-bold text-[#2C358A]">Select Destination *</p>
                }
            
            <div className="flex flex-col w-full gap-[4px]">
                <div className="flex flex-row justify-between items-center"><AgMuiUn getValues={getValues} clearErrors={clearErrors} trigger={trigger} getFieldState={getFieldState} validateAddress={validateAddress} placeholder={isFba? "Enter Amazon Warehouse Code" : "Choose Destination"} key={m.id} rules={{ required: "Destination is Required" }} isDoor={Scope === SHIPMENT_SCOPE.D2D || Scope === SHIPMENT_SCOPE.P2D} control={control} name={`multidest.${i}.destination`} disabled={viewonly} doorCountry={destcuntry} isFba={isFba}/>
                { !viewonly && multidest.length>1 &&
        <div onClick={()=>{
            removeMultidest(i);
        }} className="w-[35px] h-full flex items-center justify-center cursor-pointer">
    <img src={deleteicon}/>
        </div>
             }
            </div>
            </div></div>
        }
        
        )}
        {
            multidest.length<5 && showCargoDetail && <div className="flex flex-col w-full gap-[4px]">
            { !viewonly &&
           <button onClick={()=>{
    
        let dest = {
            destination:"",
            cargoDetails:[new Cargo()],
        }
                appendMulitdest({ ...dest });
            }} type="button" className="bg-[#F7F7F7] border-[1px] border-solid border-[#E8E8E8] h-[35px] rounded-[6px]">
                <div className="flex h-full w-full flex-row gap-[5px] justify-center items-center font-bold">
                    <Add sx={{fontSize:"22px",color:"#2C358A"}}/>
                    <p className="text-grey">Add Destination</p>
                </div>
            </button>}
            </div>
        }
        
        <div className="grid grid-cols-2 gap-3 h-[67px] place-content-stretch">
        <LabeledTextField labelStyle={{fontSize:"12px",color:"#2C358A",fontWeight:"bold"}} label="Cargo Readiness Date" control={control}  inputProps={{ min: new Date().toISOString().split('T')[0] }} name="cargoreadinessdate" type="date" size="small" fullWidth disabled={viewonly} rules={{ required: "Required", validate: validateGreaterThanZero }}/>
        <LabeledTextField labelStyle={{fontSize:"12px",color:"#2C358A",fontWeight:"bold"}} label="Customer Reference Number"  control={control} name="customerreference" disabled={crn} onBlur={(e)=>{
            callback(e)
        }}/>
        </div>
    </div>
    </div>
}